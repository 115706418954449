<template>
  <header class="govuk-header" role="banner" data-module="govuk-header">
    <div class="govuk-header__container govuk-width-container">
      <div class="govuk-header__logo">
        <a href="/" class="govuk-header__link govuk-header__link--homepage">
          <span class="govuk-header__logotype">
            <!--[if gt IE 8]><!-->
            <svg
              aria-hidden="true"
              focusable="false"
              class="govuk-header__logotype-crown"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 132 97"
              height="30"
              width="36"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M25 30.2c3.5 1.5 7.7-.2 9.1-3.7 1.5-3.6-.2-7.8-3.9-9.2-3.6-1.4-7.6.3-9.1 3.9-1.4 3.5.3 7.5 3.9 9zM9 39.5c3.6 1.5 7.8-.2 9.2-3.7 1.5-3.6-.2-7.8-3.9-9.1-3.6-1.5-7.6.2-9.1 3.8-1.4 3.5.3 7.5 3.8 9zM4.4 57.2c3.5 1.5 7.7-.2 9.1-3.8 1.5-3.6-.2-7.7-3.9-9.1-3.5-1.5-7.6.3-9.1 3.8-1.4 3.5.3 7.6 3.9 9.1zm38.3-21.4c3.5 1.5 7.7-.2 9.1-3.8 1.5-3.6-.2-7.7-3.9-9.1-3.6-1.5-7.6.3-9.1 3.8-1.3 3.6.4 7.7 3.9 9.1zm64.4-5.6c-3.6 1.5-7.8-.2-9.1-3.7-1.5-3.6.2-7.8 3.8-9.2 3.6-1.4 7.7.3 9.2 3.9 1.3 3.5-.4 7.5-3.9 9zm15.9 9.3c-3.6 1.5-7.7-.2-9.1-3.7-1.5-3.6.2-7.8 3.7-9.1 3.6-1.5 7.7.2 9.2 3.8 1.5 3.5-.3 7.5-3.8 9zm4.7 17.7c-3.6 1.5-7.8-.2-9.2-3.8-1.5-3.6.2-7.7 3.9-9.1 3.6-1.5 7.7.3 9.2 3.8 1.3 3.5-.4 7.6-3.9 9.1zM89.3 35.8c-3.6 1.5-7.8-.2-9.2-3.8-1.4-3.6.2-7.7 3.9-9.1 3.6-1.5 7.7.3 9.2 3.8 1.4 3.6-.3 7.7-3.9 9.1zM69.7 17.7l8.9 4.7V9.3l-8.9 2.8c-.2-.3-.5-.6-.9-.9L72.4 0H59.6l3.5 11.2c-.3.3-.6.5-.9.9l-8.8-2.8v13.1l8.8-4.7c.3.3.6.7.9.9l-5 15.4v.1c-.2.8-.4 1.6-.4 2.4 0 4.1 3.1 7.5 7 8.1h.2c.3 0 .7.1 1 .1.4 0 .7 0 1-.1h.2c4-.6 7.1-4.1 7.1-8.1 0-.8-.1-1.7-.4-2.4V34l-5.1-15.4c.4-.2.7-.6 1-.9zM66 92.8c16.9 0 32.8 1.1 47.1 3.2 4-16.9 8.9-26.7 14-33.5l-9.6-3.4c1 4.9 1.1 7.2 0 10.2-1.5-1.4-3-4.3-4.2-8.7L108.6 76c2.8-2 5-3.2 7.5-3.3-4.4 9.4-10 11.9-13.6 11.2-4.3-.8-6.3-4.6-5.6-7.9 1-4.7 5.7-5.9 8-.5 4.3-8.7-3-11.4-7.6-8.8 7.1-7.2 7.9-13.5 2.1-21.1-8 6.1-8.1 12.3-4.5 20.8-4.7-5.4-12.1-2.5-9.5 6.2 3.4-5.2 7.9-2 7.2 3.1-.6 4.3-6.4 7.8-13.5 7.2-10.3-.9-10.9-8-11.2-13.8 2.5-.5 7.1 1.8 11 7.3L80.2 60c-4.1 4.4-8 5.3-12.3 5.4 1.4-4.4 8-11.6 8-11.6H55.5s6.4 7.2 7.9 11.6c-4.2-.1-8-1-12.3-5.4l1.4 16.4c3.9-5.5 8.5-7.7 10.9-7.3-.3 5.8-.9 12.8-11.1 13.8-7.2.6-12.9-2.9-13.5-7.2-.7-5 3.8-8.3 7.1-3.1 2.7-8.7-4.6-11.6-9.4-6.2 3.7-8.5 3.6-14.7-4.6-20.8-5.8 7.6-5 13.9 2.2 21.1-4.7-2.6-11.9.1-7.7 8.8 2.3-5.5 7.1-4.2 8.1.5.7 3.3-1.3 7.1-5.7 7.9-3.5.7-9-1.8-13.5-11.2 2.5.1 4.7 1.3 7.5 3.3l-4.7-15.4c-1.2 4.4-2.7 7.2-4.3 8.7-1.1-3-.9-5.3 0-10.2l-9.5 3.4c5 6.9 9.9 16.7 14 33.5 14.8-2.1 30.8-3.2 47.7-3.2z"
              ></path>
            </svg>
            <!--<![endif]-->
            <!--[if IE 8]>
          <img src="/assets/images/govuk-logotype-crown.png" class="govuk-header__logotype-crown-fallback-image" width="36" height="32">
          <![endif]-->
            <span class="govuk-header__logotype-text"> KGZ.SH </span>
          </span>
        </a>
      </div>
    </div>
  </header>

  <div class="govuk-width-container">
    <main class="govuk-main-wrapper" id="main-content" role="main">
      <div class="govuk-grid-row">
        <div class="govuk-grid-column-two-thirds">
          <template v-if="state === 'startpage'">
            <h1 class="govuk-heading-l">
              Choosing a film to watch if you're undecided
            </h1>

            <p class="govuk-body">Use this service to:</p>

            <ul class="govuk-list govuk-list--bullet">
              <li>entice people to watch a questionable film</li>
              <li>blame democracy when it is poorly received</li>
              <li>
                make people read the plot for Plan 9 from Outer Space more than
                once
              </li>
            </ul>

            <p class="govuk-body">Running a vote takes around 10 minutes.</p>

            <a
              href="#"
              role="button"
              draggable="false"
              class="
                govuk-button govuk-!-margin-top-2 govuk-!-margin-bottom-8
                govuk-button--start
              "
              data-module="govuk-button"
              @click="start"
            >
              Start now
              <svg
                class="govuk-button__start-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="17.5"
                height="19"
                viewBox="0 0 33 40"
                aria-hidden="true"
                focusable="false"
              >
                <path
                  fill="currentColor"
                  d="M0 0h13l20 20-20 20H0l20-20z"
                /></svg
            ></a>

            <h2 class="govuk-heading-m">Before you start</h2>

            <p class="govuk-body">
              You can also
              <a class="govuk-link" href="#">register by post</a>.
            </p>

            <p class="govuk-body">
              The online service is also available in
              <a class="govuk-link" href="#">Welsh (Cymraeg)</a>.
            </p>

            <p class="govuk-body">
              You cannot register for this service if you’re in the UK legally.
            </p>
          </template>

          <template v-if="state === 'adding'">
            <AddFilms
              v-bind:filmList="filmList"
              @film-list-updated="filmListUpdated"
            />

            <a
              href="#"
              role="button"
              draggable="false"
              class="
                govuk-button govuk-!-margin-top-2 govuk-!-margin-bottom-8
                govuk-button--start
              "
              data-module="govuk-button"
              @click="startVoting"
            >
              Start voting
              <svg
                class="govuk-button__start-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="17.5"
                height="19"
                viewBox="0 0 33 40"
                aria-hidden="true"
                focusable="false"
              >
                <path
                  fill="currentColor"
                  d="M0 0h13l20 20-20 20H0l20-20z"
                /></svg
            ></a>
          </template>

          <template v-else-if="state === 'voting'">
            <VoteList
              v-bind:filmList="filmList"
              v-bind:ballotNumber="ballotNumber"
              @ballot-submitted="ballotSubmitted"
              @vote-ended="voteEnded"
            />
          </template>

          <template v-else-if="state === 'results'">
            <Results v-bind:inputBallots="ballots" v-bind:filmList="filmList" />
          </template>
        </div>
      </div>
    </main>
  </div>

  <footer class="govuk-footer" role="contentinfo">
    <div class="govuk-width-container">
      <div class="govuk-footer__meta">
        <div class="govuk-footer__meta-item govuk-footer__meta-item--grow">
          <span class="govuk-footer__licence-description">
            All content is available under the
            <a
              class="govuk-footer__link"
              href="http://www.wtfpl.net/about/"
              rel="license"
              >Do What The Fuck You Want To Public License</a
            >, except where otherwise stated
          </span>
        </div>
        <div class="govuk-footer__meta-item">
          <a
            class="govuk-footer__link govuk-footer__copyright-logo"
            href="http://www.wtfpl.net/about/"
            >© Probably Not Crown copyright</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import VoteList from "./components/VoteList.vue";
import AddFilms from "./components/AddFilms.vue";
import Results from "./components/Results.vue";

export default {
  name: "App",
  components: {
    VoteList,
    AddFilms,
    Results,
  },

  mounted() {
    document.body.classList.add("govuk-template__body");
  },

  data() {
    return {
      filmList: [],
      ballots: [],
      state: "startpage",
      //state: "results",
    };
  },

  computed: {
    ballotNumber() {
      return this.ballots.length + 1;
    },
  },

  methods: {
    filmListUpdated(filmList) {
      console.log("from parent:", filmList);
      this.filmList = filmList;
    },

    ballotSubmitted(ballot) {
      this.ballots.push(ballot);
      console.log("ballots", this.ballots);
    },

    startVoting() {
      console.log("starting vote");
      this.state = "voting";
    },

    voteEnded() {
      console.log("ending vote");
      this.state = "results";
    },

    start() {
      this.state = "adding";
    },
  },
};
</script>

<style lang="scss">
#app {
  color: #2c3e50;
}
</style>
