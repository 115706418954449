<template>
  <h1 class="govuk-heading-l">Film preference for voter #{{ ballotNumber }}</h1>

  <div class="govuk-warning-text">
    <span class="govuk-warning-text__icon" aria-hidden="true">!</span>
    <strong class="govuk-warning-text__text">
      <span class="govuk-warning-text__assistive">Warning</span>
      You can be fined up to 1 packets of crisps if you commit voting fraud.
    </strong>
  </div>

  <p class="govuk-body">
    Rank films in order of your personal preference, with most preferable at
    top:
  </p>

  <draggable
    class="govuk-list govuk-list--number"
    tag="transition-group"
    :component-data="{
      tag: 'ol',
      type: 'transition-group',
      name: !drag ? 'flip-list' : null,
    }"
    v-model="list"
    v-bind="dragOptions"
    @start="drag = true"
    @end="drag = false"
    item-key="order"
  >
    <template #item="{ element }">
      <li class="govuk-body-l">
        <p class="draggable">{{ element.name }} &#9776;</p>
      </li>
    </template>
  </draggable>

  <button @click="vote" class="govuk-button" data-module="govuk-button">
    Place binding vote
  </button>

  <h2 class="govuk-heading-m">After all voters have placed a vote</h2>

  <p class="govuk-body">
    Before you continue, verify all eligible voters have exercised their right
    to vote. Proceed to the vote counting phase.
  </p>

  <button
    class="govuk-button govuk-button--warning"
    data-module="govuk-button"
    @click="endVote"
  >
    End voting and see results
  </button>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "transition-example-2",
  display: "Transitions",
  order: 7,

  props: ["filmList", "ballotNumber"],
  emits: ["ballot-submitted", "vote-ended"],

  components: {
    draggable,
  },

  data() {
    return {
      list: this.filmList.map((film, index) => {
        return { name: film.name, film, order: index + 1 };
      }),
      drag: false,
    };
  },
  methods: {
    vote() {
      this.$emit(
        "ballot-submitted",
        this.list.map((film, index) => {
          return {
            name: film.name,
            candidateID: film.film.id,
            number: this.ballotNumber,
            ranking: index,
          };
        })
      );
      for (let index = 0; index < this.list.length; index++) {
        console.log(index, JSON.stringify(this.list[index]));
      }
      this.list = this.list.sort((a, b) => a.order - b.order);
    },

    endVote() {
      this.$emit("vote-ended");
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.draggable {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
