<template>
  <div class="govuk-panel govuk-panel--confirmation">
    <h1 class="govuk-panel__title">Vote counting complete</h1>
    <div class="govuk-panel__body">
      The film that will be watched <br /><strong>{{ schulze }}</strong>
    </div>
  </div>

  <p class="govuk-body">You must watch the chosen film, no backsies.</p>

  <div class="govuk-warning-text">
    <span class="govuk-warning-text__icon" aria-hidden="true">!</span>
    <strong class="govuk-warning-text__text">
      <span class="govuk-warning-text__assistive">Warning</span>
      It is a criminal offence to debate film choice after voting has concluded
    </strong>
  </div>

  <h2 class="govuk-heading-m">What happens next</h2>

  <p class="govuk-body">Go and watch the film.</p>

  <p class="govuk-body"></p>

  <p class="govuk-body">
    <a href="#" class="govuk-link">What did you think of this service?</a>
    (takes 30 seconds)
  </p>

  <h3 class="govuk-heading-m">Full results</h3>

  <pre>votersWhoPrefer {{ votersWhoPrefer }}</pre>
  <pre>strongestPaths {{ strongestPaths }}</pre>
</template>

<script>
export default {
  name: "Results",

  props: ["inputBallots", "filmList"],

  data() {
    return {
      ballots: this.inputBallots,

      /*
      ballots: [
        [
          { name: "film 1", candidateID: 1, number: 1, ranking: 0 },
          { name: "film 2", candidateID: 2, number: 1, ranking: 1 },
          { name: "film 3", candidateID: 3, number: 1, ranking: 2 },
          { name: "film 4", candidateID: 4, number: 1, ranking: 3 },
        ],
        [
          { name: "film 4", candidateID: 4, number: 2, ranking: 0 },
          { name: "film 3", candidateID: 3, number: 2, ranking: 1 },
          { name: "film 2", candidateID: 2, number: 2, ranking: 2 },
          { name: "film 1", candidateID: 1, number: 2, ranking: 3 },
        ],
        [
          { name: "film 3", candidateID: 3, number: 3, ranking: 0 },
          { name: "film 2", candidateID: 2, number: 3, ranking: 1 },
          { name: "film 1", candidateID: 1, number: 3, ranking: 2 },
          { name: "film 4", candidateID: 4, number: 3, ranking: 3 },
        ],
        [
          { name: "film 3", candidateID: 3, number: 4, ranking: 0 },
          { name: "film 1", candidateID: 1, number: 4, ranking: 1 },
          { name: "film 2", candidateID: 2, number: 4, ranking: 2 },
          { name: "film 4", candidateID: 4, number: 4, ranking: 3 },
        ],
        [
          { name: "film 1", candidateID: 1, number: 5, ranking: 0 },
          { name: "film 4", candidateID: 4, number: 5, ranking: 1 },
          { name: "film 2", candidateID: 2, number: 5, ranking: 2 },
          { name: "film 3", candidateID: 3, number: 5, ranking: 3 },
        ],
      ],

      ballots: [
        [
          { name: "robin hood", candidateID: 4, number: 1, ranking: 0 },
          { name: "the hobbit", candidateID: 2, number: 1, ranking: 1 },
          { name: "tron", candidateID: 5, number: 1, ranking: 2 },
          { name: "lord of the rings", candidateID: 1, number: 1, ranking: 3 },
          { name: "captain blood", candidateID: 3, number: 1, ranking: 4 },
        ],
        [
          { name: "tron", candidateID: 5, number: 2, ranking: 0 },
          { name: "captain blood", candidateID: 3, number: 2, ranking: 1 },
          { name: "lord of the rings", candidateID: 1, number: 2, ranking: 2 },
          { name: "the hobbit", candidateID: 2, number: 2, ranking: 3 },
          { name: "robin hood", candidateID: 4, number: 2, ranking: 4 },
        ],
        [
          { name: "captain blood", candidateID: 3, number: 3, ranking: 0 },
          { name: "the hobbit", candidateID: 2, number: 3, ranking: 1 },
          { name: "lord of the rings", candidateID: 1, number: 3, ranking: 2 },
          { name: "tron", candidateID: 5, number: 3, ranking: 3 },
          { name: "robin hood", candidateID: 4, number: 3, ranking: 4 },
        ],
        [
          { name: "lord of the rings", candidateID: 1, number: 4, ranking: 0 },
          { name: "robin hood", candidateID: 4, number: 4, ranking: 1 },
          { name: "captain blood", candidateID: 3, number: 4, ranking: 2 },
          { name: "tron", candidateID: 5, number: 4, ranking: 3 },
          { name: "the hobbit", candidateID: 2, number: 4, ranking: 4 },
        ],
      ],
        */
    };
  },

  computed: {
    votingPairs() {
      let pairs = [];
      for (let v = 0; v < this.filmList.length; v++) {
        for (let w = 0; w < this.filmList.length; w++) {
          if (v !== w) {
            pairs.push([v, w]);
          }
        }
      }
      return pairs;
    },

    votersWhoPrefer() {
      if (this.ballots.length === 0) {
        return undefined;
      }
      return this.getNumberOfVotersWhoPrefer(this.ballots);
    },

    strongestPaths() {
      if (this.ballots.length === 0) {
        return undefined;
      }
      return this.getStrongestPaths(
        this.ballots,
        this.getNumberOfVotersWhoPrefer(this.ballots)
      );
    },

    schulze() {
      if (this.ballots.length === 0) {
        return undefined;
      }

      let winnerID = this.getWinner(
        this.ballots,
        this.getStrongestPaths(
          this.ballots,
          this.getNumberOfVotersWhoPrefer(this.ballots)
        )
      );
      console.log(this.ballots[0]);
      let winnerTitle = this.ballots[0].filter((b) => {
        console.log(b.number, winnerID);
        return b.candidateID === winnerID;
      })[0].name;
      return winnerTitle;
    },

    prettyVotes() {
      return this.ballots.map((b) => {
        return (
          "order " +
          b.map((r) => {
            return r.candidateID;
          })
        );
      });
    },
  },

  methods: {
    getStrongestPaths(ballots, votersWhoPrefer) {
      let paths = {};

      // off the rails biasing to break ties
      let popularity = {};
      for (let i = 1; i <= ballots[0].length; i++) {
        popularity[i] = 0;
      }

      for (let i = 1; i <= ballots[0].length; i++) {
        for (let j = 1; j <= ballots[0].length; j++) {
          if (i !== j) {
            popularity[i] = popularity[i] + votersWhoPrefer[[i, j]];
          }
        }
      }

      let maxScore = ballots.length * (ballots[0].length - 1);

      for (let i = 1; i <= ballots[0].length; i++) {
        popularity[i] = popularity[i] / maxScore;
      }

      // back to the regular schedule
      for (let i = 1; i <= ballots[0].length; i++) {
        for (let j = 1; j <= ballots[0].length; j++) {
          if (i !== j) {
            if (votersWhoPrefer[[i, j]] > votersWhoPrefer[[j, i]]) {
              paths[[i, j]] = votersWhoPrefer[[i, j]];
            } else {
              paths[[i, j]] = 0;
            }
            // and add some off schedule action
            paths[[i, j]] = paths[[i, j]] + popularity[i];
          }
        }
      }

      for (let i = 1; i <= ballots[0].length; i++) {
        for (let j = 1; j <= ballots[0].length; j++) {
          if (i !== j) {
            for (let k = 1; k <= ballots[0].length; k++) {
              if (i !== k && j !== k) {
                paths[[j, k]] = Math.max(
                  paths[[j, k]],
                  Math.min(paths[[j, i]], paths[[i, k]])
                );
              }
            }
          }
        }
      }

      return paths;
    },

    getWinner(ballots, strongestPaths) {
      let winStatus = {};
      for (let i = 1; i <= ballots[0].length; i++) {
        winStatus[i] = true;
      }

      for (let i = 1; i <= ballots[0].length; i++) {
        for (let j = 1; j <= ballots[0].length; j++) {
          if (i !== j) {
            if (strongestPaths[[j, i]] > strongestPaths[[i, j]]) {
              winStatus[i] = false;
            }
          }
        }
      }

      let winners = [];
      for (let i = 1; i <= ballots[0].length; i++) {
        if (winStatus[i] === true) {
          winners.push(i);
        }
      }

      let winner = 0;
      if (winners.length === 1) {
        winner = winners[0];
      } else if (winners.length > 1) {
        winner = winners[Math.floor(Math.random() * winners.length)];
      } else {
        console.log("no");
      }
      return winner;
    },

    getNumberOfVotersWhoPrefer(ballots) {
      let preferences = {};

      for (let v = 1; v <= ballots[0].length; v++) {
        for (let w = 1; w <= ballots[0].length; w++) {
          if (v !== w) {
            let count = 0;
            ballots.forEach((ballot) => {
              let vPreference = ballot.filter((b) => {
                return b.candidateID === v;
              })[0].ranking;

              let wPreference = ballot.filter((b) => {
                return b.candidateID === w;
              })[0].ranking;

              if (vPreference < wPreference) {
                count++;
              }
            });
            preferences[[v, w]] = count;
          }
        }
      }
      return preferences;
    },
  },
};
</script>

<style scoped>
</style>
