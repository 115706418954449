<template>
  <div class="govuk-form-group">
    <h1 class="govuk-label-wrapper">
      <label class="govuk-label govuk-label--l" for="event-name">
        What film do you want to watch?
      </label>
    </h1>
    <div id="event-name-hint" class="govuk-hint">
      The title of a film which will appear on the ballot.
    </div>
    <input
      v-model="film"
      class="govuk-input"
      id="event-name"
      name="event-name"
      type="text"
    />
  </div>

  <button
    class="govuk-button govuk-button--secondary"
    data-module="govuk-button"
    @click="add"
  >
    Add film
  </button>

  <p class="govuk-body">Current film list:</p>

  <ol id="array-rendering" class="govuk-list govuk-list--number">
    <li v-for="film in films" :key="film.name">
      {{ film.name }}
    </li>
  </ol>
</template>

<script>
export default {
  name: "addFilms",
  display: "Transitions",
  order: 7,

  props: ["filmList"],
  emits: ["film-list-updated"],

  data() {
    return {
      films: this.filmList,
      film: "",
      nextID: 1,
    };
  },

  methods: {
    add() {
      if (this.film === "") {
        return;
      }
      console.log(this.film);
      this.films.push({ name: this.film, id: this.nextID });
      console.log(this.films);
      this.nextID++;
      this.film = "";
      this.$emit("film-list-updated", this.films);
    },
  },
};
</script>

<style scoped>
</style>
